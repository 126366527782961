import React from 'react';

const ProjectShort = ( { project } 
                ) => {
    return (
        <>
            {project.short}
        </>
    );
}

export default ProjectShort;